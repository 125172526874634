import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

import { UtilService } from '../../services/util.service';
import { InvoiceElement } from '../invoices/invoices.component';
import { InvoicesService } from '../../services/invoices.service';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { InvoiceReactivateDialogComponent } from '../invoice-reactivate-dialog/invoice-reactivate-dialog.component';

@Component({
  selector: 'app-invoice-mobile',
  templateUrl: './invoice-mobile.component.html',
  styleUrls: ['./invoice-mobile.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class InvoiceMobileComponent implements OnInit {
   
  @Input() invoices: InvoiceElement[] = [];
  @Input() selectedCurrencySymbol: string = '';
  @Input() isDataLoaded: boolean = false;

  @Output() invoicesPayment = new EventEmitter();
  @Output() radioButtonSelectionMobile = new EventEmitter<string>();
  @Output() invoicestotalAmount = new EventEmitter();
  @Output() addPendingSortFromMobile = new EventEmitter();
  @Output()  addReactivatedSortFromMobile = new EventEmitter();
  @Output()  removePendingSortFromMobile= new EventEmitter();
  @Output() removeReactivatedSortFromMobile =new EventEmitter();
  @ViewChild('paymentOverlay', { read: ElementRef, static: false })
  paymentOverlay!: ElementRef;

  radioSelectionMobile: string = '';
  isRadioButtonVisible: boolean = false;
  displayBankTransferTooltip: boolean = false;
  selection = new SelectionModel<any>(true, []);
  totalAmt: string = "0";
  displayPostcodeTooltip: boolean = false;
  displayPostcodeTooltips: boolean = false;
  private _selectedCurrency: any;

  /**
   * Get selected currency from parent and
   * take actions when currency changes
   */
  @Input() set selectedCurrency(value: any) {
    this._selectedCurrency = value;
    this.selection.clear();
    this.radioSelectionMobile = '' ;
    this.totalAmt = "0";
    this.radioButtonDisplay();
    this.setSelectedInvoices();
  }
 
  /**
   * return selected Currency
   */
  get selectedCurrency(): any {
    return this._selectedCurrency;
  }
  
  constructor( private utilService: UtilService, private invoiceService: InvoicesService, public dialog: MatDialog, ) {}
  
  ngOnInit(): void {
   // For adding and removing reactiaved and pending sort option and state 
   this.initializeSortingAndStates();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['invoices'] && this.invoices) {
      this.initializeSortingAndStates();
    }
  }
  initializeSortingAndStates(): void {
    // Manage reactivated and pending invoices and their sort options
    this.manageInvoiceReactivatedState();
    this.addPendingInvoiceSortOption();
    this.addReactivatedInvoiceSortOption();
    this.checkAndRemovePendingSortOption();
    this.checkAndRemoveReactivatedSortOption();
  }
  addPendingInvoiceSortOption(){
    // Dynamically add "Sort invoice by pending" to options
    const hasPendingInvoices = this.invoices.some(invoice => invoice.isBankTransferPending);
    if (hasPendingInvoices) {
       this.addPendingSortFromMobile.emit(true)
       this.invoices = this.sortPendingAndReactivated();
    }
  }
  addReactivatedInvoiceSortOption() {
    // Dynamically add "Sort invoice by reactivated" to options
    const hasReactivatedInvoices = this.invoices.some(invoice => invoice.reactivated)

    if ( hasReactivatedInvoices) {
      this.addReactivatedSortFromMobile.emit(true)
    }
  }
  checkAndRemovePendingSortOption() {
    const hasPendingInvoices = this.invoices.some(invoice => invoice.isBankTransferPending)

    // Remove "Sort invoice by pending" option if no pending invoices exist
    if (!hasPendingInvoices ) {
      this.removePendingSortFromMobile.emit(true);
    }
  }
  checkAndRemoveReactivatedSortOption(){
    const hasReactivatedInvoices = this.invoices.some(invoice => invoice.reactivated);

    // Remove "Sort invoice by reactivated" option if no reactivated invoices exist
    if (!hasReactivatedInvoices ) {
      this.removeReactivatedSortFromMobile.emit(true)
    }   
  }
  manageInvoiceReactivatedState() {

      const reactivatedInvoices: string[] = JSON.parse(sessionStorage.getItem('reactivatedInvoices') || '[]');
      const dataSource = [...this.invoices];

      // Update reactivated state for all cached rows
      reactivatedInvoices.forEach((invoiceRef: string) => {
        const rowIndex = dataSource.findIndex((invoice) => invoice.invoiceRef === invoiceRef);
        if (rowIndex !== -1) {
          dataSource[rowIndex].isBankTransferPending = false;
          dataSource[rowIndex].reactivated = true;
        }
      });

      // Update the data source and sort
      this.invoices = this.sortPendingAndReactivated();
    
  }
  
  sortPendingAndReactivated() {
    return this.invoices.sort((a, b) => {
      // Pending invoices come first
      if (a.isBankTransferPending && !b.isBankTransferPending) {
        return -1;
      }
      if (!a.isBankTransferPending && b.isBankTransferPending) {
        return 1;
      }

      // Reactivated invoices come next
      if (a.reactivated && !b.reactivated) {
        return -1;
      }
      if (!a.reactivated && b.reactivated) {
        return 1;
      }

      // Maintain original order for all others
      return 0;
    });
  }
  /**
   * Checks the height of the payment panel and
   * stores it in a  service variable
   */
  ngDoCheck() {
    let overlayHeight = this.paymentOverlay?.nativeElement.offsetHeight;
    this.invoiceService.setFooterBottomPadding(overlayHeight);
  }

  /**
   * Pre-select checkboxes in the invoices list
   */
  setSelectedInvoices() {
    /*
      Restore state of invoices list after user returns
      from the stripe payment screen without completing payment
    */
    if (sessionStorage.getItem('sessionId')) {

      /*
        Pre-select checkboxes in the invoices list and calculate total amount
        based on the session storage
      */
      let selection: any = sessionStorage.getItem('invoiceSelected');
      selection = JSON.parse(selection);
      selection.forEach((row: any) => this.selection.select(row));
      this.totalAmount();
  
      /* Set the previously selected radio button option */
      let radioButtonOption = sessionStorage.getItem('radioButtonSelection');
      if(radioButtonOption){
        this.radioSelectionMobile = radioButtonOption;
      }
     
      /* Clear all the session items related to invoices - after 5 seconds */
      setTimeout(() => {
        const sessionInvoiceItems: Array<string> = [ 'invoiceSelected', 'sortingOption', 'currencySelection', 'outstandingInvoice','radioButtonSelection', 'dialogBoxState', 'sessionId' ];
        this.utilService.removeInvoicesDetailsFromSession(sessionInvoiceItems);
      }, 5000);
    } else {
      //Code for selecting all invoices in the list
      this.restExpand();
      let data = this.nonGreyedInvoice();
      this.selection = new SelectionModel<InvoiceElement>(true, data);
      this.totalAmount();
    }
    sessionStorage.setItem('invoiceSelectedReactivatedTrack', JSON.stringify(this.selection.selected)); // Managing reactivated invoice state
  }
  
  /**
   * Check if all the invoices in the list are selected
   * @returns boolean value
   */
  isAllSelected(){
    return this.selection.selected.length === this.invoices.length;
  }

  /**
   * Select/unselect all the invoices in the list
   */
  toggleAllSelection(){
    if ( this.isAllSelected() ) {
      this.selection.clear();
    } else {
      this.selection.clear();
      this.invoices.forEach((row) => this.selection.select(row));
    }
  }

  /**
   * Show appropriate label for the checkbox
   * @param row (invoice)
   * @returns label for the checkbox
   */
  checkboxLabel(row?: InvoiceElement): string{
    if(!row){
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.isRowSelected(row) ? 'deselect' : 'select'} row ${row.invoiceRef + 1}`;
  }

  /**
   * Check if the clicked invoice is already selected
   * @param row (invoice)
   * @returns boolean value
   */
  isRowSelected(row: any) {
    let findSelectedInvoice: any;
    if (this.selection.selected.length) {
      findSelectedInvoice = this.selection.selected.find(invoice => invoice.invoiceRef === row.invoiceRef);
    }
    
    return findSelectedInvoice ? true : false;
  }

  /**
   * Select/unselect the clicked row
   * @param row (invoice)
   */
  onRowToggled(row: InvoiceElement): void {
    let selectedInvoices = JSON.parse(sessionStorage.getItem('invoiceSelectedReactivatedTrack') || '[]');
    let filterInvoice: any = []
    if (this.isRowSelected(row)) {
      let filteredSelection = this.selection.selected.filter(item => item.invoiceRef != row.invoiceRef);
      this.selection.clear();
      //this.selection.select(...filteredSelection);

      filterInvoice = filteredSelection.filter(item => item.isBankTransferPending === false);
      this.selection.select(...filterInvoice);
      // Remove the invoice from the session variable
      selectedInvoices = selectedInvoices.filter((invoice: any) => invoice.invoiceRef !== row.invoiceRef);
    } else {
      this.selection.select(row);
      // Add the invoice to the session variable
      selectedInvoices.push(row);
    }
    // Update session storage
    sessionStorage.setItem('invoiceSelectedReactivatedTrack', JSON.stringify(selectedInvoices));
  }

  /**
   * Calculate the total amount to be paid
   * based on the selected invoices
   */
  totalAmount() {
    this.totalAmt = "0";
    this.selection.selected.forEach((invoice) => {
      let totalAmont = parseFloat(this.totalAmt) + +invoice.totalAmount;
      this.totalAmt = parseFloat("" + totalAmont).toFixed(2);
      this.invoicestotalAmount.emit(this.totalAmt);
    });
  }

  /**
   * Find and return the invoices user will be allowed to select
   * @param invoices 
   * @returns array of invoices
   */
  nonGreyedInvoice(invoices?:any){
    let filterInvoice : any = []
    if(invoices){
      filterInvoice = invoices.filter((item:any) => item.isBankTransferPending === false);
    }
    else{
    filterInvoice = this.invoices.filter(item => item.isBankTransferPending === false);
    }
    return filterInvoice;
  }

  /**
   * Send selected invoices to parent component
   * for making payment API call
   */
  makePayment() {
    this.invoicesPayment.emit({ 'selection': this.selection.selected , 'radioSelection': this.radioSelectionMobile });
  }

  /**
   * Expand/Collapse current invoice to view/hide multiple policies
   * @param invoices 
   * @param event 
   */
  toggleExpand(invoice: InvoiceElement): void {
    invoice.isExpanded = !invoice.isExpanded;
  }
 
  /**
   * to reset the state of exapnded row in the table on currency change
   */
  restExpand(){
      let data = this.nonGreyedInvoice();
      for(let i=0; i < data.length; i++) {
        data[i].isExpanded = false;
      }
 }

  /*
      Hide/Show radio buttons based on currency selection
  */
  radioButtonDisplay(){ 
    this.isRadioButtonVisible = (this.selectedCurrency === "Pound Sterling" );
  
    if(!this.isRadioButtonVisible){
     this.radioSelectionMobile = 'card';
    }
  }
 
  /**
   *  Disable/enable proceed to pay button based on different condition
   * proceef to pay button enable only when user select invoice and radion button and 
   * total amount of selected  invoice is less than maximum limit 
   *  
   */
  disablePaymentButton(){
    if( !this.selection.selected.length ) return true;
    if( !this.radioSelectionMobile ) return true;
    if( this.radioSelectionMobile === 'card' && +this.totalAmt > 20000.00 ) return true;
    if( this.radioSelectionMobile === 'pay_by_bank' && +this.totalAmt > 999999999.99 ) return true;

    return false;
 }

  /*
     Send selected radio button value  to parent component    
  */
  radioButtonValue() {
     this.radioButtonSelectionMobile.emit(this.radioSelectionMobile);
  }
  openReactivateDialog(row: any , event: Event): void {
    sessionStorage.setItem("invoiceRef", row.invoiceRef);
    sessionStorage.setItem("reactivated", 'false');
    //event.stopPropagation();
    const dialogRef = this.dialog.open(InvoiceReactivateDialogComponent, {
      // width: '750px',
      maxWidth: '65vw',
      width: '65vw',
      panelClass: 'custom-dialog', 
      data: { },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const reactivated = sessionStorage.getItem('reactivated') === 'true';
        if (reactivated) {
          const invoiceRef = sessionStorage.getItem('invoiceRef');
          const dataSource = [...this.invoices];

          // Update the row based on invoiceRef
          const rowIndex = dataSource.findIndex((invoice) => invoice.invoiceRef === invoiceRef);
          if (rowIndex !== -1) {
            dataSource[rowIndex].isBankTransferPending = false;
            dataSource[rowIndex].reactivated = true;
          }

          // Update the data source
          this.invoices = this.sortPendingAndReactivated();;
          this.storeReactivatedState(invoiceRef);
          this.manageInvoiceReactivatedState()
          this.checkAndRemovePendingSortOption();
          this.addReactivatedInvoiceSortOption();
        }
      } else {
//        console.log('Dialog closed without action.');
      }
    });
  }
  storeReactivatedState(invoiceRef: any) {
    let reactivatedInvoices = sessionStorage.getItem('reactivatedInvoices');
    const updatedReactivated = reactivatedInvoices ? JSON.parse(reactivatedInvoices) : [];
    if (!updatedReactivated.includes(invoiceRef)) {
      updatedReactivated.push(invoiceRef);
    }
    sessionStorage.setItem('reactivatedInvoices', JSON.stringify(updatedReactivated));
  }
}
