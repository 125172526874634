import { ChangeDetectorRef, Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InvoicesService } from 'src/app/services/invoices.service';

@Component({
  selector: 'app-invoice-reactivate-dialog',
  templateUrl: './invoice-reactivate-dialog.component.html',
  styleUrls: ['./invoice-reactivate-dialog.component.scss']
})
export class InvoiceReactivateDialogComponent implements OnInit {
  currentState: 'default' | 'loader' | 'error' | 'success' = 'default'; // Tracks the current section
  showContent: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<InvoiceReactivateDialogComponent>,
    @Inject (MAT_DIALOG_DATA) public data: any,
    private invoiceService: InvoicesService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() { }

  closeDialog(event?: MouseEvent) {
    this.dialogRef.close(true);
    this.cdr.detectChanges();
  }

  reactivatePendingInvoice() {

    this.currentState = 'loader'; // Show the loader section
    if (sessionStorage.getItem('clientRef')) {
      const clientRef = sessionStorage.getItem('clientRef');
      const postCode = sessionStorage.getItem('postCode');
      const bladeCode = sessionStorage.getItem('bladeCode');
      const invoiceRef = sessionStorage.getItem('invoiceRef');
      const payload = { clientRef, postCode, bladeCode, invoiceRef };
      this.invoiceService.reactivateInvoice(payload).subscribe({
        next: (data: any) => {
          if (data) {
            sessionStorage.setItem('reactivated', 'true');
            this.currentState = 'success';
          } else {
            this.currentState = 'error';
          }
        },
        error: () => {
          this.currentState = 'error';
        }
      });
    }
  }
}
