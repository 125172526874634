import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UtilService } from '../../services/util.service';
import { LoginService } from '../../services/login.service';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  title: string = "Thank you";
  paymentId: string = '';
  count: any;
  paymentIdError: boolean = false;
  isMobileView: boolean = false;

  selectedInvoices: any[] = JSON.parse(sessionStorage.getItem('invoiceSelectedReactivatedTrack') || '[]');
  reactivatedInvoices: string[] = JSON.parse(sessionStorage.getItem('reactivatedInvoices') || '[]'); 

  // Sets a flag if the screen size is less than 992px
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.isMobileView = window.innerWidth <= 992;   
  }

  constructor (
    private router: Router,
    private utilService: UtilService,
    private loginService: LoginService,
    private paymentService: PaymentService
  ) { }

  ngOnInit(): void {
    this.getPaymentDetails();
    this.countOfOutstandingInvoice();

    this.isMobileView = window.innerWidth <= 992;
  }

  /**
   * Used to display the count of remaining oustanding invoices 
   * after completing payment
   */
  countOfOutstandingInvoice(){
    this.count = sessionStorage.getItem('outstandingInvoice');

    // Clears previously stored session items
    const sessionInvoiceItems: Array<string> = [ 'invoiceSelected', 'sortingOption', 'currencySelection', 'outstandingInvoice','radioButtonSelection','dialogBoxState' ];
    this.utilService.removeInvoicesDetailsFromSession(sessionInvoiceItems);
  }

  /**
   * Used to get the details of the payment
   * based on the payment session ID
   */
  getPaymentDetails() {
    this.paymentService.getPaymentDetails().subscribe(
      (res: any) => {
        if (res) {
          this.paymentId = res.id;
          this.paymentIdError = false;

          if (this.paymentId) {

            // Filter out matching invoices from both selectedInvoices and reactivatedInvoices
            this.selectedInvoices = this.selectedInvoices.filter((invoice: any) => {
              const isReactivated = this.reactivatedInvoices.includes(invoice.invoiceRef);

              // Remove matching invoiceRef from reactivatedInvoices
              if (isReactivated) {
                this.reactivatedInvoices = this.reactivatedInvoices.filter(
                  (ref: string) => ref !== invoice.invoiceRef
                );
              }
              // Return false to exclude the invoice from selectedInvoices
              return !isReactivated;
            });

            // Update sessionStorage for reactivatedInvoices
            if (this.reactivatedInvoices.length > 0) {
              sessionStorage.setItem('reactivatedInvoices', JSON.stringify(this.reactivatedInvoices));
            } else {
              sessionStorage.removeItem('reactivatedInvoices');
              sessionStorage.setItem('reactivated', 'false');
            }

            // Update sessionStorage for selectedInvoices
            if (this.selectedInvoices.length > 0) {
              sessionStorage.setItem(
                'invoiceSelectedReactivatedTrack',
                JSON.stringify(this.selectedInvoices)
              );
            } else {
              sessionStorage.removeItem('invoiceSelectedReactivatedTrack');
            }
          }
        }
      },
      (err) => {
        this.paymentIdError = true;
        console.error(err);
      }
    );
  }
 

  /**
   * Called on click of "Return to portal" button
   */
  redirectToInvoices() {
    if(sessionStorage.getItem("sessionId")) {
      sessionStorage.removeItem("sessionId");
      this.router.navigate(['/invoices']);
    } else {
      this.router.navigate(['/']);
    }
  }

  /**
   * Called on click of Exit button
   */
  signOut() {
    this.loginService.signOut();
  }
}
