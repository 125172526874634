import { Component, Inject, OnInit, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { InvoicesService } from '../../services/invoices.service';

const images = require('../../../assets/json/bank_logos.json');
@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./payment-dialog.component.scss']
})

export class PayByBankDialogComponent implements OnInit {
 
  isExpanded: boolean = false;
  isExpandedByBank: boolean =true;
  isBankTransfer: boolean = true;
  totalAmount: string = '0';
  logos = images;
  @Output() paymentType = new  EventEmitter<string>;

  stripeError: boolean = false;
  subscription: Subscription | undefined;
  radioSelectionButton: any;
  constructor(
    private invoiceService: InvoicesService,
    public dialogRef: MatDialogRef<PayByBankDialogComponent>,
    @Inject (MAT_DIALOG_DATA) public data: any
  ) {
    this.totalAmount = data.invoiceTotalAmt;
    this.radioSelectionButton = data.radioSelection
    console.log(data)
  }
 
  ngOnInit(): void {
    // Subscription to check if payment API calls fail in invoices component
    this.subscription = this.invoiceService.getInvoicesAPIErrorFlag().subscribe(flag => {
      if (flag) {
        this.stripeError = true;
      } else {
        this.stripeError = false;
      }
    });
  }

  /**
   * Cancel the subscription on component destroy
   */
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  /**
   * Called when any of the payment button is clicked
   * @param paymentType 
   */
  onPaymentButtonClick (paymentType: string){
    this.paymentType.emit(paymentType);
  }

  /**
   * Called when the close button (X) is clicked
   */
  closeDialog(){
    this.dialogRef.close();
   }
   
}
