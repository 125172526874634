<div class="login-wrapper">
    <div class="container">
        <div class="login-inner-wrapper row">
            <div class="col-xs-12 offset-lg-1 col-lg-5">
                <div class="title-wrapper">
                    <app-page-title [title]="title"></app-page-title>
                    <div class="description">
                        Pay your outstanding invoices at any time through the Gallagher Portal. Start by entering your client reference and postcode. These details can be found on your Gallagher documentation.
                    </div>
                </div>
            </div>
            <div class="col-xs-12 offset-lg-1 col-lg-5">
    
                <div class="form-wrapper">
                    <div class="auth-error-wrapper" *ngIf="authFailed" [hidden]="isLoader">
                        The details you have entered below do not match our records. Please enter the correct details and retry.
                    </div>
                    <form [formGroup]="loginForm" [hidden]="isLoader" >
                        <!-- Blade Field -->
                        <div class="field-wrapper">
                            <div class="label-wrapper">
                                <mat-label class="color-secondary font-primary-bold">Select your portal gateway</mat-label>
                                <div class="tooltip-wrapper">
                                    <img src="../assets/images/input-tooltip.svg" alt="input-tooltip" class="input-tooltip" (click)="displayBladeTooltip = !displayBladeTooltip" />
                                    <div class="tooltip-message-wrapper" *ngIf="displayBladeTooltip">
                                        Portal gateway is at top of invoice or contact your Gallagher representative.
                                        <span class="close-tooltip-wrapper">
                                            <img src="../assets/images/close.svg" alt="close-tooltip" class="close-tooltip" (click)="displayBladeTooltip = false" />
                                        </span>
                                        <span class="tooltip-arrow-wrapper">
                                            <img src="../assets/images/tooltip.svg" alt="tooltip-arrow" class="tooltip-arrow" />
                                          </span>
                                    </div>
                                </div>
                            </div>
                                <mat-form-field class="blade-dropdown"   appearance="outline">
                                    <mat-select 
                                        placeholder="Select a portal gateway" 
                                        formControlName="bladeName"
                                        [(ngModel)]="selectedBladeName" 
                                        (selectionChange)="onBladeChange($event)"   
                                        disableOptionCentering  
                                        panelClass="bladePanelClass" 
                                        [ngClass]= "{'icon-collapse' : select?.panelOpen }" #select  >
                                        <mat-option *ngFor="let item of bladeNames" [value]="item" >
                                            {{item}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="error-block" *ngIf="(getFormControls?.bladeName?.touched || getFormControls?.bladeName?.dirty)">
                                <mat-error  *ngIf="getFormControls?.bladeName?.errors?.['required']"
                                    >Please select a portal gateway</mat-error
                                >
                                </div>
                        </div>
                        
                            <!-- Client Reference Field -->
                        <div class="field-wrapper" >
                            <div class="label-wrapper">
                                <mat-label id="ClientRefLabel" class="color-secondary font-primary-bold">Enter your client reference</mat-label>
                                <div class="tooltip-wrapper">
                                    <img src="../assets/images/input-tooltip.svg" alt="input-tooltip" class="input-tooltip" (click)="displayClientRefTooltip = !displayClientRefTooltip" />
                                    <div class="tooltip-message-wrapper" *ngIf="displayClientRefTooltip">
                                        Your Client Reference can be found at the top of your invoice document.
                                        <span class="close-tooltip-wrapper">
                                            <img src="../assets/images/close.svg" alt="close-tooltip" class="close-tooltip" (click)="displayClientRefTooltip = false" />
                                        </span>
                                        <span class="tooltip-arrow-wrapper">
                                            <img src="../assets/images/tooltip.svg" alt="tooltip-arrow" class="tooltip-arrow" />
                                          </span>
                                    </div>
                                </div>
                            </div>
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="e.g 12345678" formControlName="clientRef" required [attr.aria-labelledBy]="'ClientRefLabel'">
                            </mat-form-field>
                            <div
                                class="error-block"
                                *ngIf="!getFormControls?.clientRef?.valid && (getFormControls?.clientRef?.touched || getFormControls?.clientRef?.dirty)"
                            >
                                <mat-error *ngIf="getFormControls?.clientRef?.errors?.['required']"
                                    >Please enter the client reference</mat-error
                                >
                                <mat-error  *ngIf="getFormControls?.clientRef?.errors">
                                    That Client reference is not valid. please check your documentation and re-enter your Client reference number
                                </mat-error>
                                <!-- <mat-error *ngIf="getFormControls?.clientRef?.errors?.['pattern']"
                                    >Client reference should contain only alphanumeric characters</mat-error
                                >
                                <mat-error *ngIf="getFormControls?.clientRef?.errors?.['maxlength']"
                                    >Client Reference cannot exceed 16 characters</mat-error
                                > -->
                            </div>
                        </div>
                    
                        <!-- Postcode Field -->
                        <div class="field-wrapper">
                            <div class="label-wrapper">
                                <mat-label id="PostCodeLabel" class="color-secondary font-primary-bold">Enter your postcode</mat-label>
                                <div class="tooltip-wrapper">
                                    <img src="../assets/images/input-tooltip.svg" alt="input-tooltip" class="input-tooltip" (click)="displayPostcodeTooltip = !displayPostcodeTooltip" />
                                    <div class="tooltip-message-wrapper" *ngIf="displayPostcodeTooltip">
                                        This is the postcode of the address your invoice has been issued to.
                                        <span class="close-tooltip-wrapper">
                                            <img src="../assets/images/close.svg" alt="close-tooltip" class="close-tooltip" (click)="displayPostcodeTooltip = false" />
                                        </span>
                                        <span class="tooltip-arrow-wrapper">
                                            <img src="../assets/images/tooltip.svg" alt="tooltip-arrow" class="tooltip-arrow" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="e.g EC4N 8AF" formControlName="postCode" required [attr.aria-labelledBy]="'PostCodeLabel'">
                            </mat-form-field>
                            <div
                                class="error-block"
                                *ngIf="!getFormControls?.['postCode']?.valid && (getFormControls?.['postCode']?.touched || getFormControls?.['postCode']?.dirty)"
                            >
                                <mat-error *ngIf="getFormControls?.['postCode']?.errors?.['required']"
                                    >Please enter the postcode</mat-error
                                >
                                <mat-error  *ngIf="getFormControls?.postCode?.errors">
                                    Please enter a valid zip or postcode format
                                </mat-error>
                                <!-- <mat-error *ngIf="getFormControls?.['postCode']?.errors?.['incorrectSpacePattern']"
                                    >Only space is not allowed</mat-error
                                >
                                <mat-error *ngIf="getFormControls?.['postCode']?.errors?.['pattern']"
                                    >Postcode should contain only alphanumeric characters</mat-error
                                >
                                <mat-error *ngIf="getFormControls?.['postCode']?.errors?.['maxlength']"
                                    >Postcode cannot exceed 10 characters</mat-error
                                > -->
                            </div>
                        </div>
                
                        <!-- Google Recaptcha -->
                        <div class="g-recaptcha">
                            <!-- <ngx-recaptcha2
                                #captchaElem
                                [siteKey]="siteKey"
                                formControlName="recaptcha"
                            >
                            </ngx-recaptcha2> -->
                            <re-captcha #captchaElems
                            [siteKey] ="siteKey"
                            size="invisible">
                            </re-captcha> 
                        </div>
                        <!-- (success)="handleSuccess($event)" -->
    
                        <button mat-flat-button color="primary" id="btnLogin" [disabled]="!loginForm.valid" (click)="authenticateUser()">
                            Submit
                            <img src="../assets/images/arrow-grey.svg" class="btn-icon icon-disabled" />
                            <img src="../assets/images/arrow-white.svg" class="btn-icon icon-active" />
                        </button>
                    </form>
                    <div class="loader-background" *ngIf="isLoader">
                        <div class="loader"></div>
                        <div class="loader-text">Please wait while we verify your details...</div>
                    </div>      
                </div>
            </div>
        </div>
    </div>
</div>