import { Component, EventEmitter, HostListener, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';

import { UtilService } from '../../services/util.service';
import { LoginService } from '../../services/login.service';
import { PaymentService } from '../../services/payment.service';
import { InvoicesService } from '../../services/invoices.service';
import { SessionTimeoutService } from '../../services/session-timeout.service';

import { PayByBankDialogComponent } from '../payment-dialog/payment-dialog.component';

import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { InvoiceReactivateDialogComponent } from '../invoice-reactivate-dialog/invoice-reactivate-dialog.component';
import { NavigationEnd, Router } from '@angular/router';

export interface CurrencyModel {
  label: string;
  symbol: string;
}

export interface PolicyDetails {
  policy: string;
  amount: string;
}

export interface InvoiceElement {
  invoiceDate: string;
  invoiceRef: string;
  isExpanded?: boolean; // to check if the invoice has multiple policy.
  policyDetails?: Array<PolicyDetails>;
  totalAmount: string;
  isBankTransferPending?: boolean; // to implement greying of invoices.
  reactivated?: boolean; // Add this property to track reactivation
}
 
export interface AllInvoicesModel {
  [key: string]: Array<InvoiceElement>
}

// Empty data for showing skeleton table on page load
const invoicesDataForSkeletonTable = [
  { 'invoiceDate': '', 'invoiceRef': '', 'policyDetails': [], 'totalAmount': '' },
  { 'invoiceDate': '', 'invoiceRef': '', 'policyDetails': [], 'totalAmount': '' },
  { 'invoiceDate': '', 'invoiceRef': '', 'policyDetails': [], 'totalAmount': '' },
  { 'invoiceDate': '', 'invoiceRef': '', 'policyDetails': [], 'totalAmount': '' },
  { 'invoiceDate': '', 'invoiceRef': '', 'policyDetails': [], 'totalAmount': '' }
];

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./invoices.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class InvoicesComponent implements OnInit {
  title: string = "Your outstanding invoices";

  displayedColumns: string[] = [
    'select',
    'invoiceDate',
    'invoiceRef',
    'policyDetails',
    'totalAmount'
  ];
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  expandedElement: InvoiceElement | undefined;
  
  dataSource = new MatTableDataSource<InvoiceElement>();
  selection = new SelectionModel<InvoiceElement>(true, []);
  allInvoices: AllInvoicesModel = {};
  noInvoices: boolean = false;
  responseError: boolean = false;
  stripeError: boolean = false;
  radioSelection: string = '';
  isDataLoaded: boolean = false;
  expandedElements:any[]=[];
  displayPostcodeTooltip: boolean = false;
  selectedInvoicesRefs: string[] = [];
  // dropdown selection option
  options = [
    { value: 'dateAscending', label: 'Sort date by oldest' },
    { value: 'dateDescending', label: 'Sort date by newest' },
    { value: 'amtLowest', label: 'Sort amount by lowest' },
    { value: 'amtHighest', label: 'Sort amount by highest' },
  ];
  selectedOption: string = 'dateAscending';
  
  totalAmt: string = "0";
  totalCount: number = 0;

  selectedCurrency!: string;
  selectedCurrencySymbol!: string;
  currencies: Array<CurrencyModel> = [];
  displayCurrencyTooltip: boolean = false;
  displayBankTransferTooltip: boolean = false;

  invoiceMobileData:any;
  radioButtonMobile:string = '';
  showListView: boolean = false;

  isRadioButtonVisible: boolean = false;

  isDialogBoxOpen:boolean = false;
  dialogPaymentType:string = '';
  dialogRef!: MatDialogRef<PayByBankDialogComponent>;

  isSafariBrowser:boolean = false;
  reactivatedInvoice:any;
  // Sets a flag if the screen size is less than 992px
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.showListView = window.innerWidth <= 992;   
  }

  constructor(
    private utilService: UtilService,
    private loginService: LoginService,
    private paymentService: PaymentService,
    private invoiceService: InvoicesService,
    private sessionTimeout :SessionTimeoutService,
    public dialog: MatDialog,
    private titleService:Title,
    private router: Router
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.dataSource.data = invoicesDataForSkeletonTable; // Setting empty data array to show the skeleton table
    this.selection.clear();
    this.getInvoices();
    // call invoices when redirect from success page so that isBankTransfer updated correctly
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.urlAfterRedirects.includes('/invoices')) {
        this.getInvoices(); // API call
      }
    });

    this.sessionTimeout.resetTimer();
    this.sessionTimeout.sessionTimeoutEvent.subscribe(() => {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    });
    this.showListView = window.innerWidth <= 992;

    //To check safari browser
    this.isSafariBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    //Auto refresh when user click on browser back button 
    window.addEventListener("pageshow", function (event) {
      let historyTravel = event.persisted ||
        (typeof window.performance != "undefined" &&
          window.performance.navigation.type === 2);
      if (historyTravel) {
        window.location.reload();
      }
    });

    // Check and clean sorting options
    if (!this.showListView) {
      this.checkAndRemovePendingSortOption();
      this.checkAndRemoveReactivatedOption();
    }

  }

  /**
   * Get the invoices from the API and take appropriate actions
   * based on the fetched data
   */
  getInvoices() {
    if (sessionStorage.getItem("clientRef")) { // Check to see if user is logged in
        let clientRef = sessionStorage.getItem('clientRef');
        let postCode = sessionStorage.getItem('postCode');
        let bladeCode = sessionStorage.getItem('bladeCode');
        let payload = { clientRef, postCode, bladeCode };

      this.invoiceService.getInvoices(payload).subscribe((data: any) => { // API call to fetch invoices
        this.responseError = false;
        if (data && data.length) { // Check for empty data
          this.noInvoices = false;
          this.processInvoices(data);
          let updatedData: any = {};
          data.forEach((item: any) => {  
            // Sets the currencies to be displayed in Currency Dropdown          
            let currency = {
              label: item.currencyName,
              symbol: item.currencySymbol
            };
            this.currencies.push(currency);

            //this.totalCount += item.invoiceCount;
            // Calculate total count of invoices accross all currencies
            this.totalCount += this.nonGreyedInvoice(item.invoiceModel).length;

            let invoices = item.invoiceModel;
            updatedData[item.currencyName] = invoices;
          });
          // Set an object with keys as currency name and value as respective array of invoices 
       //   this.allInvoices = updatedData;        
          this.allInvoices = updatedData;          
  
          this.setDefaultCurrency();

          this.isDataLoaded = true;
          // Call  methods after data is loaded to manage reactivate, pending state + sort option which come dynamically
          if(!this.showListView){
          this.manageInvoiceReactivatedState();
          this.addPendingInvoiceSortOption();
          this.addReactivatedInvoiceSortOption();
          this.checkAndRemovePendingSortOption();
          this.checkAndRemoveReactivatedOption();
          }
        } else {
          // Set flags when the data is fetched but is empty
          this.isDataLoaded = true;
          this.noInvoices = true;
        }
      }, _err => {
        // Set flag to display error message when API call fails
        this.responseError = true;
      });
    }
  }
  addPendingInvoiceSortOption(){
    // Dynamically add "Sort invoice by pending" to options
    const hasPendingInvoices = this.dataSource.data.some(invoice => invoice.isBankTransferPending);
    if (hasPendingInvoices) {
      this.options.push({ value: 'pending', label: 'Sort invoice by pending' });
      this.selectedOption = 'pending';
      this.dataSource.data = this.sortPendingAndReactivated();
    }
  }
  addReactivatedInvoiceSortOption() {
    // Dynamically add "Sort invoice by reactivated" to options
    const hasReactivatedInvoices = this.dataSource.data.some(invoice => invoice.reactivated);
    const pendingIndex = this.options.findIndex(option => option.value === 'pending');

    if (pendingIndex == -1 && hasReactivatedInvoices) {
      this.options.push({ value: 'reactivated', label: 'Sort invoice by reactivated' });
      this.selectedOption = 'reactivated';
    }
  }

  manageInvoiceReactivatedState() {
    if (this.sessionTimeout.isUserLoggedIn()) {
      const reactivatedInvoices: string[] = JSON.parse(sessionStorage.getItem('reactivatedInvoices') || '[]');
      const dataSource = [...this.dataSource.data];

      // Update reactivated state for all cached rows
      reactivatedInvoices.forEach((invoiceRef: string) => {
        const rowIndex = dataSource.findIndex((invoice) => invoice.invoiceRef === invoiceRef);
        if (rowIndex !== -1) {
          dataSource[rowIndex].isBankTransferPending = false;
          dataSource[rowIndex].reactivated = true;
        }
      });

      // Update the data source and sort
      this.dataSource.data = this.sortPendingAndReactivated();
    }
  }
 // check and remove reactivated invoice of it is now in pending (payment process senario)
  processInvoices(data: any[]) {
    let reactivatedInvoices = JSON.parse(sessionStorage.getItem('reactivatedInvoices') || '[]');

    data.forEach((item: any) => {
      item.invoiceModel.forEach((invoice: any) => {
        // Check if the invoice is in the reactivated list
        if (reactivatedInvoices.includes(invoice.invoiceRef)) {

          if (invoice.isBankTransferPending === true) {
            // Remove the invoice from reactivatedInvoices if it is now pending
            reactivatedInvoices = reactivatedInvoices.filter((ref: string) => ref !== invoice.invoiceRef);
          }
        }
      });
    });

    // Update session storage
    if (reactivatedInvoices.length > 0) {
      sessionStorage.setItem('reactivatedInvoices', JSON.stringify(reactivatedInvoices));
    } else {
      sessionStorage.removeItem('reactivatedInvoices');
      sessionStorage.setItem('reactivated', 'false');
    }
  }
  
  
  /**
   * Sets the default currency when the page loads
   */
  setDefaultCurrency() {
    let storedCurrency = sessionStorage.getItem('currencySelection');   
    if (storedCurrency) {
      this.selectedCurrency = storedCurrency; // When returning from stripe portal
    } else {
      this.selectedCurrency = this.currencies[0].label; // On first load
    }

    this.onCurrencySelection();
    this.onOptionSelection();
  }

  /**
   * Called when currency value is updated in the Currency dropdown
   */
  onCurrencySelection() {
    this.expandedElements=[];
    this.selection.clear();
    this.selectedOption = 'dateAscending';
    this.totalAmt = "0";
    this.radioSelection = '';
    this.radioButtonDisplay();

    // Get the respective invoices from invoices data model
    this.dataSource.data = this.allInvoices[this.selectedCurrency];
    // Set symbol of the selected currency to be used in the payment panel and table column
    this.selectedCurrencySymbol = this.getCurrencySymbol(this.selectedCurrency);

    if (sessionStorage.getItem('sessionId')) { 
        /* if(!this.showListView) {
            sessionStorage.removeItem('sessionId');
        } */     
      /*
        Restore state of invoices list after user returns
        from the stripe payment screen without completing payment
      */
      let sorting = sessionStorage.getItem('sortingOption');
      if (!sorting) this.selectedOption = 'dateAscending'; // On fresh update
      else this.selectedOption = sorting; // When returning from Stripe portal

      // Set selected invoices when returning from Stripe portal
      let selection: any = sessionStorage.getItem('invoiceSelected');
      selection = JSON.parse(selection);
      selection.forEach((row: any) => this.selection.select(row));
      this.totalAmount();
      
      // Set selected radio button option when returning from Stripe portal
      let radioButtonOption = sessionStorage.getItem('radioButtonSelection');
      if(radioButtonOption){
        this.radioSelection = radioButtonOption;
      }
     
      // Set pay by bank dialog box state when returning from Stripe portal
      let popUpState = sessionStorage.getItem('dialogBoxState');
      if(popUpState === 'true'){
        if(this.showListView){
          let data: any = sessionStorage.getItem('invoiceSelected');
          this.invoiceMobileData = JSON.parse(data);
          let payType = sessionStorage.getItem('paymentDialogType');
          this.dialogPaymentType = JSON.stringify(payType)
        }
        this.openDialog();
      }
     
      // Clear all the session items related to invoices - after 10 seconds
      if(!this.showListView) {
          setTimeout(() => {
            const sessionInvoiceItems: Array<string> = [ 'invoiceSelected', 'sortingOption', 'currencySelection', 'outstandingInvoice', 'radioButtonSelection', 'dialogBoxState', 'sessionId' ];
            this.utilService.removeInvoicesDetailsFromSession(sessionInvoiceItems);
          }, 5000);
      }

    } else {
      //Code for selecting all rows in the table
      //this.selection.select(...this.dataSource.data);
      let data = this.nonGreyedInvoice(); // Filter non-greyed out invoices
      this.selection.select(...data); // Select non-greyed out invoices
      this.selectedOption = 'dateAscending';
      this.onOptionSelection();
      this.totalAmount();
    }
  }

  /**
   * To get the symbol of the selected currency
   * @param currencyName 
   * @returns currency symbol
   */
  getCurrencySymbol(currencyName: string) {
    if (currencyName) {
      let selectedCurrency = this.currencies.find(currency => currency.label === currencyName);
      
      if (selectedCurrency) return selectedCurrency.symbol;
      return '';
    }
    return '';
  }

  /**
   * When sorting option changes
   */
  onOptionSelection() {    
    let selectedValue = this.selectedOption;

    if(selectedValue === 'amtHighest') {
      this.dataSource.data = this.sortAmtHighest();
    }

    if(selectedValue === 'amtLowest') {
      this.dataSource.data = this.sortAmtLowest();
    }

    if(selectedValue === 'dateDescending') {
      this.dataSource.data = this.sortDateDescending();
    }

    if(selectedValue === 'dateAscending') {
      this.dataSource.data = this.sortDateAscending();
    }
    if(selectedValue === 'pending') {
      this.dataSource.data = this.sortPendingAndReactivated();
    }
    if(selectedValue === 'reactivated') {
      this.dataSource.data = this.sortPendingAndReactivated();
    }
  }
  
    
  /**
   * To sort table data in ascending order by date
   * @returns sorted data 
   */
  sortDateAscending() {
    let currencyName: string = this.selectedCurrency;
    let invoices = [...this.allInvoices[currencyName]];

    let result: InvoiceElement[] = [];
    for(let i = invoices.length-1; i >= 0; i--) {
      result.push(invoices[i]);
    }

    return result;
  }

  /**
   * To sort table data in descending order by date
   * @returns sorted data 
   */
  sortDateDescending() {
    let currencyName: string = this.selectedCurrency;
    return [...this.allInvoices[currencyName]];
  }
  
  /**
   * To sort table data by amount lowest to highest
   * @returns sorted data 
   */
  sortAmtLowest() {
    let result=this.dataSource.data.sort(
      (a1, a2) => (+a1.totalAmount > +a2.totalAmount) ? 1 : (+a1.totalAmount < +a2.totalAmount) ? -1 : 0);
    return result;
  }
  
  /**
   * To sort table data by amount highest to  lowest 
   * @returns sorted data 
   */
  sortAmtHighest() {
    let result=this.dataSource.data.sort(
      (a1, a2) => (+a1.totalAmount < +a2.totalAmount) ? 1 : (+a1.totalAmount > +a2.totalAmount) ? -1 : 0);
    return result;
  }

  /**
   * To sort table data by pending invoices or reactivated if have
   * @returns sorted data
   */
  sortPendingAndReactivated() {
    return this.dataSource.data.sort((a, b) => {
      // Pending invoices come first
      if (a.isBankTransferPending && !b.isBankTransferPending) {
        return -1;
      }
      if (!a.isBankTransferPending && b.isBankTransferPending) {
        return 1;
      }

      // Reactivated invoices come next
      if (a.reactivated && !b.reactivated) {
        return -1;
      }
      if (!a.reactivated && b.reactivated) {
        return 1;
      }

      // Maintain original order for all others
      return 0;
    });
  }
  
  /**
   * Filter and return invoices for which bank tranfer is not pending
   * @param invoices 
   * @returns filtered invoices
   */
  nonGreyedInvoice(invoices?: Array<any>) {
    let filterInvoice: Array<any> = [];
    if (invoices) {
      filterInvoice = invoices.filter((item:any) => item.isBankTransferPending === false);
    } else {
      filterInvoice = this.dataSource.data.filter(item => item.isBankTransferPending === false);
    }
    return filterInvoice;
  }

  /**
   * Check if all the invoices in the table are selected
   * @returns boolean value
   */
  isAllSelected() {
    //return this.selection.selected?.length == this.dataSource.data?.length;
    let  data = this.nonGreyedInvoice();
    return !!( data?.length && this.selection.selected?.length == data?.length ); 
  }

  /**
   * Select/unselect all the invoices in the table
   * OR
   * Selects all rows if they are not all selected; otherwise clear selection.
   */
  toggleAllRows(): void {
    let selectedInvoices = JSON.parse(sessionStorage.getItem('invoiceSelectedReactivatedTrack') || '[]'); // only added to manage reactivated paid invoice 
    if (this.isAllSelected()) {
      this.selection.clear();
      selectedInvoices = []; // Clear the session variable
    } else {
      this.selection.clear();
      let data = this.nonGreyedInvoice();
      data.forEach((row: any) => this.selection.select(row));
      //this.dataSource.data.forEach((row) => this.selection.select(row));  

      // Update the session variable with all selected invoices
      selectedInvoices = data.map((row: any) => ({
        invoiceRef: row.invoiceRef,
        invoiceDate: row.invoiceDate,
        totalAmount: row.totalAmount,
      }));
    }
    // Update session storage
    sessionStorage.setItem('invoiceSelectedReactivatedTrack', JSON.stringify(selectedInvoices));
  }

  /**
   * Show appropriate label for the checkbox based on selection value
   * @param row (invoice)
   * @returns label for the checkbox
   */
  checkboxLabel(row?: InvoiceElement): string{
    if(!row){
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.isRowSelected(row) ? 'deselect' : 'select'} row ${row.invoiceRef + 1}`;
  }

  /**
   * Check if the clicked invoice is already selected
   * @param row (invoice)
   * @returns boolean value
   */
  isRowSelected(row: any) {
    let findSelectedInvoice: any;
    if (this.selection.selected.length) {
      findSelectedInvoice = this.selection.selected.find(invoice => invoice.invoiceRef === row.invoiceRef);
    }
    
    return findSelectedInvoice ? true : false;
  }

  /**
   * Select/unselect the clicked row
   * @param row (invoice)
   */
  onRowToggled(row: InvoiceElement): void {
    let selectedInvoices = JSON.parse(sessionStorage.getItem('invoiceSelectedReactivatedTrack') || '[]'); // Manage for reactivated paid invoice to track state
    if (this.isRowSelected(row)) {
      let filteredSelection = this.selection.selected.filter(item => item.invoiceRef != row.invoiceRef);
      this.selection.clear();
      this.selection.select(...filteredSelection);
      // Remove the invoice from the session variable
      selectedInvoices = selectedInvoices.filter((invoice: any) => invoice.invoiceRef !== row.invoiceRef);
    } else {
      this.selection.select(row)
      // Add the invoice to the session variable
      selectedInvoices.push(row);
    }
    // Update session storage
    sessionStorage.setItem('invoiceSelectedReactivatedTrack', JSON.stringify(selectedInvoices));
  }

  /**
   * Calculate the total amount to be paid
   * based on the selected invoices
   */
  totalAmount() {
    this.totalAmt = "0";
    this.selection.selected.forEach((invoice) => {
      let totalAmont = parseFloat(this.totalAmt) + +invoice.totalAmount;

      //Rounding off the total to 2 decimal places
      this.totalAmt = parseFloat("" + totalAmont).toFixed(2);
    });
  }

  /**
   * Returns true if selected currency has an invoice with pending bank transfer
   * else returns false
   * @returns boolean
   */
  isPendingInvoice(): boolean {
    let data: any;
    data = this.dataSource.data.find((item:any) => item.isBankTransferPending === true)
    return data ? true : false ;
  } 

  /**
   * Preparing payload for the payment API call
   * based on the selected invoices
   * @param selectedInvoices 
   */
  preparePaymentPayload(selectedInvoices: Array<any>) {
    let successUrl: string = environment.frontendURL + '/success';
    let cancelUrl: string = environment.frontendURL + '/invoices';
    let clientRef = sessionStorage.getItem('clientRef'); 
    let postCode = sessionStorage.getItem('postCode');
    let bladeCode = sessionStorage.getItem('bladeCode');
    let paymentType: string = '';

    if (this.dialogPaymentType) {
      paymentType = this.dialogPaymentType;
    } else {
      if (this.showListView) {
        paymentType = this.radioButtonMobile;
      } else {
        paymentType = this.radioSelection;
      }
    }

    let paymentDetails: Array<any> = [];
    selectedInvoices.forEach((element: any) => {     
      let data = {
        invoiceRef: element.invoiceRef,
        currencyCode: element.currencyCode,
        accountingEntityCode: element.accountingEntityCode,
        accountEntityRef: element.entity
      };

      element.policyDetails.forEach((policyObj: any) => {
        let policyDetail = {
          policy: policyObj.policy,
          amount: policyObj.amount
        };
        paymentDetails.push({ ...data, ...policyDetail});
      });
      
    });

    let payload = { successUrl, cancelUrl, clientRef, postCode, bladeCode, paymentType, paymentDetails };
    this.paySelectedInvoices(payload);
    if (!this.showListView) {
      this.checkAndRemovePendingSortOption();
      this.checkAndRemoveReactivatedOption();
    }

  }

  /**
   * Payment API call integration and
   * response/error handling
   * @param payload 
   */
  paySelectedInvoices(payload: any) {
    this.paymentService.payInvoices(payload).subscribe((result: any) => {
      this.stripeError = false;
      // Pay by bank transfer
      if (
        this.radioSelection === 'customer_balance' ||
        this.dialogPaymentType === 'customer_balance' ||
        this.radioButtonMobile === 'customer_balance'
      ) {  
        const sessionInvoiceItems: Array<string> = [ 'invoiceSelected', 'sortingOption', 'currencySelection', 'outstandingInvoice', 'radioButtonSelection', 'dialogBoxState' ];
        this.utilService.removeInvoicesDetailsFromSession(sessionInvoiceItems);
      } else {
        sessionStorage.setItem('sessionId', result.id);
      }
      this.invoiceService.setInvoicesAPIErrorFlag(false);
      window.location.href = result.url;
    }, (err: any) => {
      this.stripeError = true;
      this.invoiceService.setInvoicesAPIErrorFlag(true);
      console.error(err);
    });
  }

  /**
   * Storing state of the current screen in session storage
   * Stored entities - selected currency, selected sort option, selected invoices
   */
  storeInvoicesState() {
    let selectedInvoices: any;
    let remainingInvoice: any;
    let dialogBoxState: any;
    let radioButtonOption: any;

    if(this.showListView) {
      selectedInvoices = this.invoiceMobileData;
      if(this.invoiceMobileData?.length){
          remainingInvoice = this.totalCount - this.invoiceMobileData.length;
      }
      radioButtonOption = this.radioButtonMobile;
    } else {
      selectedInvoices = this.selection.selected;
      this.selectedInvoicesRefs = this.selection.selected.map((invoice) => invoice.invoiceRef);
      this.removeReactivatedInvoicesAfterPayByTransfer();
      remainingInvoice = this.totalCount - (this.selection.selected.length)
      radioButtonOption = this.radioSelection;
    } 
    dialogBoxState = this.isDialogBoxOpen;

    /* 
    Replace the below if/else with this
    if (
      (this.radioButtonMobile === 'pay_by_bank' || this.radioSelection === 'pay_by_bank') &&
      !this.dialogPaymentType
    ) {
      if (this.sessionTimeout.isUserLoggedIn()) {
        this.isDialogBoxOpen = true;
        this.openDialog();
      }  
      return;
    }
    */

    if(this.showListView) {
      if ((this.radioButtonMobile === 'pay_by_bank' || this.radioSelection === 'pay_by_bank_transfer') && !this.dialogPaymentType) {
        if (this.sessionTimeout.isUserLoggedIn()) {
          this.isDialogBoxOpen = true;
          this.openDialog();
        }  
        return;
      }
    }
    else {
      if ((this.radioSelection === 'pay_by_bank' || this.radioSelection === 'pay_by_bank_transfer') && !this.dialogPaymentType) {
        if (this.sessionTimeout.isUserLoggedIn()) {
          this.isDialogBoxOpen = true;
          this.openDialog();
        }  
        return;
      }
    }

    if (
      this.radioSelection === 'card' ||
      this.radioSelection === 'customer_balance' ||
      this.dialogPaymentType ||
      this.radioButtonMobile === 'card' ||
      this.radioButtonMobile === 'customer_balance'
    ) {
      sessionStorage.setItem('invoiceSelected', JSON.stringify(selectedInvoices));
      sessionStorage.setItem('invoiceSelectedReactivatedTrack', JSON.stringify(selectedInvoices)); // Managing reactivated invoice state
      sessionStorage.setItem('outstandingInvoice', JSON.stringify(remainingInvoice)); // Count of the remaining outstanding invoices
      sessionStorage.setItem('sortingOption', this.selectedOption); 
      sessionStorage.setItem('currencySelection', this.selectedCurrency);
      sessionStorage.setItem('radioButtonSelection', radioButtonOption);
      sessionStorage.setItem('dialogBoxState', dialogBoxState);
      sessionStorage.setItem('paymentDialogType',this.dialogPaymentType);
      this.preparePaymentPayload(selectedInvoices);
      this.sessionTimeout.stopTimer();
    }
  }

  /**
   * Add classes to expanded rows
   * based on the index of parent
   */
  addClassBasedOnIndex(index: number) {
    return ((index + 1) % 2 == 0) ? 'odd-detailed-row' : 'even-detailed-row';
  }

  /**
   * Handling data emitted from child component
   * Child component - InvoiceMobileComponent
   * data - selected invoices
   * @param data 
   */
  invoicesListPaymentHandler(data: any) {
    this.invoiceMobileData = data.selection;
    this.radioSelection = data.radioSelection
    setTimeout(() => {
      this.storeInvoicesState();
    });
  }
     
  /**
   * Handling data emitted from child component
   * Child component - InvoiceMobileComponent
   * data - radio buttons selection
   * @param data 
   */
  radioButtonSelectionHandler(data: string) {
    this.radioButtonMobile = data;
    setTimeout(() => {
      this.storeInvoicesState();
    });
  }

  /**
   * Handling data emitted from child component
   * Child component - InvoiceMobileComponent
   * data - total amount of selected invoices
   * @param data 
   */
  invoiceMobileAmtHandler(data:string) {
    this.totalAmt = data;
  }

  /*
    To hide/show of radio buttons based on currency selection
   */
  radioButtonDisplay() { 
    /* 
        // Euro - bank transfer changes
    this.isRadioButtonVisible = (this.selectedCurrency === "Pound Sterling" || this.selectedCurrency === "Euro"  ); */
    this.isRadioButtonVisible = this.selectedCurrency === "Pound Sterling";
    if (!this.isRadioButtonVisible) {
      this.radioSelection = 'card';
    }
  }
  
  /**
   *  Disable/enable proceed to pay button based on different condition
   * proceed  to pay button enable only when user select invoice and radion button and 
   * total amount of selected  invoice is less than maximum limit 
   *  
   */
  disablePaymentButton(){
     if( !this.selection.selected.length ) return true;
     if( !this.radioSelection ) return true;
     if( this.radioSelection === 'card' && +this.totalAmt > 20000.00 ) return true;
     if( (this.radioSelection === 'pay_by_bank' || this.radioSelection === 'pay_by_bank_transfer') && +this.totalAmt > 999999999.99 ) return true;

     return false;
  }

  openDialog() {
    // Prevent opening multiple dialogs if one is already open
    if (this.isDialogBoxOpen && this.dialogRef?.getState() === MatDialogState.OPEN) {
      return;
    }

    // Ensure user is logged in before opening the dialog
    if (this.sessionTimeout.isUserLoggedIn()) {
      this.dialogRef = this.dialog.open(PayByBankDialogComponent, {
        autoFocus: false,
        height: '100%',
        data: { invoiceTotalAmt: this.totalAmt, radioSelection: this.radioSelection },
      });
      this.isDialogBoxOpen = true;
      this.dialogRef.componentInstance.paymentType.subscribe((dialogData) => {
        this.dialogPaymentType = dialogData;
        setTimeout(() => {
          this.storeInvoicesState();
        });
      });

      this.dialogRef.afterClosed().subscribe(() => {
        this.isDialogBoxOpen = false;
        this.dialogPaymentType = '';
        this.stripeError = false;
      });
    } else {
      console.error('User is not logged in. Cannot open dialog.');
    }
  }

  /**
   * Stop timer for checking ideal time
   */
  stopForRedirect(){
    this.sessionTimeout.stopTimer();
  }

  /**
   * Logout of the payment portal app
   */
  signOut() {
    this.loginService.signOut();
  }

  //to store the expanded or collapsed state of invoiceElemet.
  toggleRow(invoice: InvoiceElement){
    const index = this.expandedElements.findIndex(x=>x.invoiceRef==invoice.invoiceRef);
    if ( index === -1 ) {
      this.expandedElements.push(invoice);
    } else {
      this.expandedElements.splice(index,1);
    }
  }

  //to set lable and its icon.
  expandCollapseLable( element:InvoiceElement ):boolean {
    
    const elementIndex = this.expandedElements.findIndex( x => x.invoiceRef==element.invoiceRef );
    return !!(elementIndex > -1);
  }

  //to display expanded table
  toggleExpandPanel(invoice:InvoiceElement):string {
    if((this.expandedElements.findIndex(x => x.invoiceRef==invoice.invoiceRef)!== -1)){
      return 'expanded';
    }
      return 'collapsed';
  }

  openReactivateDialog(row: any): void {
    if (this.isDialogBoxOpen && this.dialogRef?.getState() === MatDialogState.OPEN) {
      return;
    }
    
    if (this.sessionTimeout.isUserLoggedIn()) {
      sessionStorage.setItem("invoiceRef", row.invoiceRef);
      sessionStorage.setItem("reactivated", 'false');
  
      const dialogRef = this.dialog.open(InvoiceReactivateDialogComponent, {
        width: '65vw',
        panelClass: 'custom-dialog',
        data: { invoice: row },
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const reactivated = sessionStorage.getItem('reactivated') === 'true';
          if (reactivated) {
            const invoiceRef = sessionStorage.getItem('invoiceRef');
            const dataSource = [...this.dataSource.data];
            this.totalCount += 1;
            // Update reactivated state
            const rowIndex = dataSource.findIndex((invoice) => invoice.invoiceRef === invoiceRef);
            if (rowIndex !== -1) {
              dataSource[rowIndex].isBankTransferPending = false;
              dataSource[rowIndex].reactivated = true;
            }
  
            // Update data and dynamically add "Reactivated" sort option
            this.dataSource.data = this.sortPendingAndReactivated();
            this.storeReactivatedState(invoiceRef);
  
            // Ensure options are updated
            this.checkAndRemovePendingSortOption();
            this.addReactivatedInvoiceSortOption();
          }
        }
      });
    } else {
      this.dialogRef.close();
      this.dialog.closeAll();
    }
  }
  
    
  storeReactivatedState(invoiceRef: any) {
    let reactivatedInvoices = sessionStorage.getItem('reactivatedInvoices');
    const updatedReactivated = reactivatedInvoices ? JSON.parse(reactivatedInvoices) : [];
    if (!updatedReactivated.includes(invoiceRef)) {
      updatedReactivated.push(invoiceRef);
    }
    sessionStorage.setItem('reactivatedInvoices', JSON.stringify(updatedReactivated));
  }
  checkAndRemovePendingSortOption() {
    const hasPendingInvoices = this.dataSource.data.some(invoice => invoice.isBankTransferPending);
    const pendingIndex = this.options.findIndex(option => option.value === 'pending');

    // Remove "Sort invoice by pending" option if no pending invoices exist
    if (!hasPendingInvoices && pendingIndex !== -1) {
      this.options.splice(pendingIndex, 1);
    }
  }

  checkAndRemoveReactivatedOption() {
    const hasReactivatedInvoices = this.dataSource.data.some(invoice => invoice.reactivated);
    const reactivatedIndex = this.options.findIndex(option => option.value === 'reactivated');

    // Remove "Sort invoice by reactivated" option if no reactivated invoices exist
    if (!hasReactivatedInvoices && reactivatedIndex !== -1) {
      this.options.splice(reactivatedIndex, 1);
    }
  }
  
  
  /**
 * Removes reactivated invoices from session storage if the following conditions are met:
 * 1. Reactivated is true in session storage.
 * 2. The invoice has isBankTransferPending set to true.
 */
  removeReactivatedInvoicesAfterPayByTransfer() {
    let reactivatedInvoices = JSON.parse(sessionStorage.getItem('reactivatedInvoices') || '[]');

    this.selectedInvoicesRefs.forEach((invoiceRef) => {
      const invoiceData = this.dataSource.data.find((invoice) => invoice.invoiceRef === invoiceRef);

      // Only remove from reactivated if isBankTransferPending is true
      if (invoiceData?.isBankTransferPending) {
        reactivatedInvoices = reactivatedInvoices.filter((ref: string) => ref !== invoiceRef);
      }
    });
    sessionStorage.setItem('reactivatedInvoices', JSON.stringify(reactivatedInvoices));
  }
  
// Method to manage dynamic sorting from mobile for pending and reactivated options
  addPendingSortFromMobile(event: boolean) {
    if (event) {
      const pendingOptionExists = this.options.some(option => option.value === 'pending');

      if (!pendingOptionExists) {
        this.options.push({ value: 'pending', label: 'Sort invoice by pending' });
        this.selectedOption = 'pending';
      }
    }
  }

  addReactivatedSortFromMobile(event: boolean) {
    if (event) {
      const reactivatedOptionExists = this.options.some(option => option.value === 'reactivated');
      const pendingIndex = this.options.findIndex(option => option.value === 'pending');
      if (!reactivatedOptionExists && pendingIndex == -1) {
        this.options.push({ value: 'reactivated', label: 'Sort invoice by reactivated' });
        this.selectedOption = 'reactivated';
      }
    }
  }
  removePendingSortFromMobile(event: boolean) {
    if (event) {
      const pendingIndex = this.options.findIndex(option => option.value === 'pending');

      // Remove "Sort invoice by pending" option if no pending invoices exist
      if (pendingIndex !== -1) {
        this.options.splice(pendingIndex, 1);
      }
    }
  }

  removeReactivatedSortFromMobile(event: boolean) {
    if (event) {
      const reactivatedIndex = this.options.findIndex(option => option.value === 'reactivated');

      // Remove "Sort invoice by reactivated" option if no reactivated invoices exist
      if (reactivatedIndex !== -1) {
        this.options.splice(reactivatedIndex, 1);
      }
    }

  }

}